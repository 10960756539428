// import { useState, useEffect } from "react";
// import GetCategoryItemService from "../services/GetCategoryItemService";
// import {
//   MYPRODUCT,
//   SESSION_ID,
//   DEBUG_LOG_ENABLED,
//   USER_ROLE,
//   ROLE,
//   BASE_URL_IMG,
// } from "../Constant";
// import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import swal from "sweetalert";
// import "./Banner.css";

// function BannerIndicatorButton({ index, active, onClick }) {
//   return (
//     <button
//       type="button"
//       className={`btn btn-primary  ${active ? "active" : ""}`}
//       onClick={onClick}
//       aria-current={active}
//       // data-bs-target="#bannerIndicators"
//       data-bs-slide-to={index}
//       style={{
//         // backgroundColor: "transparent",
//         // border: "none",
//         // borderRadius: "50%",
//         marginRight: "10px",
//        // width: "150px",
//       }}
//     >
//       <span>20 Aprial</span>
//     </button>
//   );
// }


// // function BannerIndicatorButton(props) {
// //   return (
// //     <div className="mx-4">
// //       <button
// //         type="button"
// //         data-bs-target="#bannerIndicators"
// //         data-bs-slide-to={props.index}
// //         className={
// //           "btn btn-primary text-nowrap bd-highlight p-2" + props.active
// //             ? "active"
// //             : ""
// //         }
// //         aria-current={props.active}
// //         //style={{ backgroundColor: "trans  parent", border: "none" }}
// //       >     hiii
// //         {/* <button
// //           type="button"
// //           class="btn btn-primary text-nowrap bd-highlight p-2"
// //           style={{ width: "5rem" }}
// //         >
// //           <span>20 Aprial</span>
// //         </button> */}
// //       </button>
// //     </div>
// //   );
// // }


// // function BannerIndicatorButton({ index, active, onClick }) {
// //   return (
// //     <button
// //       type="button"
// //       data-bs-slide-to={index}
// //       data-bs-target="#bannerIndicators"
// //       className={`btn btn-primary ${active ? "active" : ""}`}
// //       onClick={onClick}
// //       aria-current={active}
// //       style={{
// //         // backgroundColor: "transparent",
// //          border: "none",
// //         // borderRadius: "50%",
// //         marginRight: "10px",
// //       }}
// //     >
// //       {index}
// //     </button>
// //   );
// // }

// // function BannerIndicatorButton({ index, active, onClick }) {
// //   return (
// //     <button
// //       type="button"
// //       className={`btn ${active ? "active" : ""}`}
// //       onClick={onClick}
// //       aria-current={active}
// //       data-bs-target="#bannerIndicators"
// //       style={{
// //         backgroundColor: "transparent",
// //         border: "none",
// //         borderRadius: "50%",
// //         marginRight: "10px",
// //       }}
// //     >
// //       {index}
// //     </button>
// //   );
// // }
// function BannerImage(props) {
//   const navigate = useNavigate();
//   let [showEdit, setShowEdit] = useState(false);

//   useEffect(() => {
//     const userRole = localStorage.getItem(USER_ROLE);
//     if (userRole?.includes(ROLE)) {
//       setShowEdit(true);
//     }
//   }, []);

//   const myStyle = {
//     backgroundImage: `url(${BASE_URL_IMG + props.image})`,
//     backgroundSize: "cover",
//     backgroundRepeat: "no-repeat",
//     position: "relative", // Set position relative
//     borderRadius: "30px",
//   };

//   const textContainerStyle = {
//     position: "absolute",
//     top: "50%", // Position at the center vertically
//     left: "50%",
//     transform: "translate(-50%, -50%)", // Center vertically
//     backgroundColor: "rgba(243, 202, 21, 0.9)", // Adjust the background color and opacity
//     padding: "10px",
//     borderRadius: "30px",
//   };

//   return (
//     <div
//       className={
//         " shadow  mb-5 bg-body rounded border border-1 rounded-5   carousel-item " +
//         (props.active ? "active" : "")
//       }
//       data-bs-interval="3000"
//       style={{ ...myStyle }}
//     >
//       <div
//         className="ratio "
//         style={{
//           "--bs-aspect-ratio": "75%",
//           maxHeight: "510px",
//           padding: "10px",
//           position: "relative", // Ensure relative positioning for textContainerStyle
//         }}
//       >
//         <div style={textContainerStyle}>
//           <p className="position-absolute fw-bold  start-50 mt-5 mx-3 fs-3 ">
//             {props.item.title}
//             <br />
//             <span className="fs-6 fst-normal fw-normal">
//               Center aligned text on all viewport sizes.
//             </span>
//             <br />
//             <br />
//             <span>
//               <button
//                 class="btn btn-primary text-light fs-4"
//                 type="button"
//                 style={{ paddingRight: 130, paddingLeft: 130 }}
//               >
//                 Book now
//               </button>
//             </span>
//           </p>

//           <p className="position-absolute  bottom-0 end-0  me-5 fs-3 ">
//             &lt; march &gt;
//           </p>
//         </div>
//         <Link
//           to={
//             props.item.callingLink ? "" : "/productdetail/" + props.item.itemId
//           }
//           onClick={() =>
//             navigate(
//               "productdetail/" + props.item.itemId,
//               localStorage.setItem(MYPRODUCT, JSON.stringify(props.item))
//             )
//           }
//         >
//           <img
//             className="d-block w-50 h-100  cover   shadow  mb-5 bg-body rounded border border-1 rounded-5 "
//             alt=""
//             src={BASE_URL_IMG + props.image}
//             style={{}}
//           />
//         </Link>
//         {showEdit && (
//           <Link
//             className="btn btn-primary"
//             style={{ width: 50, height: 50 }}
//             to={"/productdetail/" + props.item.itemId}
//             onClick={() =>
//               navigate(
//                 "productdetail/" + props.item.itemId,
//                 localStorage.setItem(MYPRODUCT, JSON.stringify(props.item))
//               )
//             }
//           >
//             Edit
//           </Link>
//         )}
//       </div>
//     </div>
//   );
// }

// function Banner(product) {
//   const bannerCatId = 11;
//   const [catItemList, setCatItemList] = useState([]);
//   const [activeIndex, setActiveIndex] = useState(0);
//   const navigate = useNavigate();

//   const getCategoryLists = async () => {
//     try {
//       const categoryListData = JSON.stringify({
//         pubInfo: {
//           sessionId: localStorage.getItem(SESSION_ID),
//         },
//         request: {
//           busiParams: {
//             catId: bannerCatId,
//           },
//           isEncrypt: false,
//           transactionId: "897987987989",
//         },
//       });
//       const response = await GetCategoryItemService.create(categoryListData);
//       setCatItemList(response.data.respData.respMsg.catItemList);
//     } catch (err) {
//       swal("Error", err.message || "An error occurred", "error");
//       if (DEBUG_LOG_ENABLED) {
//         console.error(err);
//       }
//     }
//   };

//   useEffect(() => {
//     getCategoryLists();
//   }, []);

//   const handlePrev = () => {
//     setActiveIndex((prevIndex) =>
//       prevIndex === 0 ? catItemList.length - 1 : prevIndex - 1
//     );
//   };

//   const handleNext = () => {
//     setActiveIndex((prevIndex) =>
//       prevIndex === catItemList.length - 1 ? 0 : prevIndex + 1
//     );
//   };

//   return (
//     <div
//       id="bannerIndicators"
//       className="carousel slide rounded-5"
//       data-bs-ride="carousel"
//     >
//       <div className="carousel-inner mt-3">
//         {catItemList?.map((it, index) => (
//           <BannerImage
//             key={index}
//             item={it}
//             image={it.iconURL}
//             active={index === activeIndex}
//           />
//         ))}
//       </div>
//       {/* <div className="carousel-indicators"> */}
//       <div
//         className=" carousel-indicators w-100 "
//         style={{  overflowX: "auto" }}
//       >
//         {catItemList?.map((it, index) => (
//           <BannerIndicatorButton
//             key={index}
//             index={index}
//             active={index === activeIndex}
//             onClick={() => setActiveIndex(index)}
//           />
//         ))}
//       </div>

//       <button
//         className="carousel-control-prev"
//         type="button"
//         onClick={handlePrev}
//       >
//         <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//         <span className="visually-hidden">Previous</span>
//       </button>
//       <button
//         className="carousel-control-next"
//         type="button"
//         onClick={handleNext}
//       >
//         <span className="carousel-control-next-icon" aria-hidden="true"></span>
//         <span className="visually-hidden">Next</span>
//       </button>
//     </div>
//   );
// }

// export default Banner;

import React, { useState, useEffect } from "react";
import GetCategoryItemService from "../services/GetCategoryItemService";
import {
  MYPRODUCT,
  SESSION_ID,
  DEBUG_LOG_ENABLED,
  USER_ROLE,
  ROLE,
  BASE_URL_IMG,
} from "../Constant";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";


function BannerIndicatorButton({ item,index, active, onClick }) {
   const navigate = useNavigate();
  let [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
    }
  }, []);
  return (
    <>
      <button
        type="button"
        className={`btn btn-primary ${active ? "active" : ""}`}
        onClick={onClick}
        aria-current={active}
        data-bs-slide-to={index}
        style={{ marginRight: "10px" }}
      >
        <span className="card-title text-center text-dark text-truncate">
          20 Aprial
        </span>
      </button>
      {showEdit && (
        <Link
          className="btn btn-primary"
          style={{ width: 50, height: 50 }}
          to={"/productdetail/" + item.itemId}
          onClick={() =>
            navigate(
              "productdetail/" + item.itemId,
              localStorage.setItem(MYPRODUCT, JSON.stringify(item))
            )
          }
        >
          Edit
        </Link>
      )}
    </>
  );
}

function BannerImage({ item, image, active }) {
  const navigate = useNavigate();
  let [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
    }
  }, []);

  const myStyle = {
    backgroundImage: `url(${BASE_URL_IMG + image})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "relative", // Set position relative
    borderRadius: "30px",
   // boxShadow: "2px 18px 7px -3px rgba(0,0,0,0.1)",

    boxShadow: "0px 7px 7px 10px rgba(0,0,0,0.1)",

  };

  const textContainerStyle = {
    position: "absolute",
    top: "50%", // Position at the center vertically
    left: "50%",
    transform: "translate(-50%, -50%)", // Center vertically
    backgroundColor: "rgba(243, 202, 21, 0.8)", // Adjust the background color and opacity
    padding: "10px",
    borderRadius: "30px",
  };

  return (
    <div
      className={`  rounded-5 mb-5 bg-body carousel-item ${
        active ? "active" : ""
      }`}
      data-bs-interval="2000"
      style={{ ...myStyle }}
    >
      <div
        className="ratio "
        style={{
          "--bs-aspect-ratio": "75%",
          maxHeight: "510px",
          padding: "10px",
          position: "relative", // Ensure relative positioning for textContainerStyle
        }}
      >
        <div style={textContainerStyle}>
          <p className="position-absolute fw-bold  start-50 mt-5 mx-4 fs-4 ">
            {item.title.length > 200
              ? item.title.substring(0, 200) + ".."
              : item.title}
            <br />
            <span className="fs-6 fst-normal fw-bold">
              {item.subTitleID.length > 300
                ? item.subTitleID
                    .replace(/(<([^>]+)>)/gi, "")
                    .substring(0, 300) + ".."
                : item.subTitleID.replace(/(<([^>]+)>)/gi, "")}
            </span>
            <br />
            <br />
            <span>
              <button
                class="btn btn-primary text-light fs-4 rounded-4 "
                type="button"
                style={{ paddingRight: 90, paddingLeft: 90 }}
              >
                Book now
              </button>
            </span>
          </p>

          <p className="position-absolute  bottom-0 end-0  me-5 fs-4 text-light">
            &lt; Shree &gt;
          </p>
        </div>
        <Link
          to={item.callingLink ? "" : "/productdetail/" + item.itemId}
          onClick={() =>
            navigate(
              "productdetail/" + item.itemId,
              localStorage.setItem(MYPRODUCT, JSON.stringify(item))
            )
          }
        >
          <img
            className=" w-50 h-100  cover     border border-1 rounded-5 "
            alt=""
            src={BASE_URL_IMG + image}
            style={{
              boxShadow: "6px 6px 11px 3px rgba(0,0,0,0.1)",
            }}
          />
        </Link>
        {showEdit && (
          <Link
            className="btn btn-primary"
            style={{ width: 50, height: 50 }}
            to={"/productdetail/" + item.itemId}
            onClick={() =>
              navigate(
                "productdetail/" + item.itemId,
                localStorage.setItem(MYPRODUCT, JSON.stringify(item))
              )
            }
          >
            Edit
          </Link>
        )}
      </div>
    </div>
  );
}

function BannerImageone({ item, image, active }) {
  const navigate = useNavigate();
  let [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
    }
  }, []);

 

  return (
    <div
      className={` shadow-lg rounded-5 mb-5 bg-body carousel-item ${
        active ? "active" : ""
      }`}
      data-bs-interval="2000"
    >
      <div
        className="ratio "
        style={{
          "--bs-aspect-ratio": "75%",
          maxHeight: "510px",
          padding: "10px",
          position: "relative", // Ensure relative positioning for textContainerStyle
        }}
      >
        {/* <div 
        style={textContainerStyle} >
          <p className="position-absolute fw-bold  start-50 mt-5 mx-3 fs-3 ">
            {item.title}
            <br />
            <span className="fs-6 fst-normal fw-normal">
              Center aligned text on all viewport sizes.
            </span>
            <br />
            <br />
            <span>
              <button
                class="btn btn-primary text-light fs-4"
                type="button"
                style={{ paddingRight: 130, paddingLeft: 130 }}
              >
                Book now
              </button>
            </span>
          </p>

          <p className="position-absolute  bottom-0 end-0  me-5 fs-3 ">
            &lt; march &gt;
          </p>
        </div> */}
        <Link
          to={item.callingLink ? "" : "/productdetail/" + item.itemId}
          onClick={() =>
            navigate(
              "productdetail/" + item.itemId,
              localStorage.setItem(MYPRODUCT, JSON.stringify(item))
            )
          }
        >
          <img
            className=" w-100 h-100  cover   border border-1 rounded-2 "
            alt=""
            src={BASE_URL_IMG + image}
            style={{
              boxShadow: "6px 6px 11px 3px rgba(0,0,0,0.1)",
            }}
          />
        </Link>
        {showEdit && (
          <Link
            className="btn btn-primary"
            style={{ width: 50, height: 50 }}
            to={"/productdetail/" + item.itemId}
            onClick={() =>
              navigate(
                "productdetail/" + item.itemId,
                localStorage.setItem(MYPRODUCT, JSON.stringify(item))
              )
            }
          >
            Edit
          </Link>
        )}
      </div>
    </div>
  );
}

function Banner() {
  const bannerCatId = 11;
  const [catItemList, setCatItemList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();

  const getCategoryLists = async () => {
    try {
      const categoryListData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            catId: bannerCatId,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      const response = await GetCategoryItemService.create(categoryListData);
      setCatItemList(response.data.respData.respMsg.catItemList);
    } catch (err) {
      swal("Error", err.message || "An error occurred", "error");
      if (DEBUG_LOG_ENABLED) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    getCategoryLists();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) =>
        prevIndex === 0 ? catItemList.length - 1 : prevIndex - 1
      );
    }, 5000); // Adjust the interval time as needed (5000ms = 5 seconds)

    return () => clearInterval(interval);
  }, [catItemList]);

  return (
    <div
      id="bannerIndicators"
      className="carousel slide rounded-5"
      data-bs-ride="carousel"
    >
      {/* <div className=" " style={{ marginTop: 60 }}>
        <div className="row justify-content-center  rounded-3     ">
          <button type="button" class=" col-auto btn btn-primary">
            March
          </button>
          <button type="button" class=" col-auto btn btn-primary">
            Year
          </button>
          <button type="button" class=" col-auto btn btn-primary">
            Occasion
          </button>
        </div>
      </div> */}
      <div
        className=" d-none d-md-block "
        style={{ marginTop: 60, marginBottom: 5 }}
      >
        <div className="row justify-content-center">
          <div className="col-auto  rounded-3 px-3 bg-info  ">
            <button type="button" className="btn  mx-2 text-light">
              March
            </button>
            <button
              type="button"
              className="btn btn-primary mx-2 px-5 text-light "
            >
              Year
            </button>
            <button type="button" className="btn  mx-2 text-light ">
              Occasion
            </button>
          </div>
        </div>
      </div>
      <div className="carousel-inner  d-none d-md-block ">
        {catItemList?.map((it, index) => (
          <BannerImage
            key={index}
            item={it}
            image={it.iconURL}
            active={index === activeIndex}
          />
        ))}
      </div>

      <div
        className="carousel-inner   d-block d-md-none "
        style={{ marginTop: 60 }}
      >
        {catItemList?.map((it, index) => (
          <BannerImageone
            key={index}
            item={it}
            image={it.iconURL}
            active={index === activeIndex}
          />
        ))}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        onClick={() =>
          setActiveIndex((prevIndex) =>
            prevIndex === 0 ? catItemList.length - 1 : prevIndex - 1
          )
        }
      >
        <span
          className="carousel-control-prev-icon bg-info rounded-5 "
          aria-hidden="true"
        ></span>
        <span className="visually-hidden">Previous</span>
      </button>

      <button
        className="carousel-control-next"
        type="button"
        onClick={() =>
          setActiveIndex((prevIndex) =>
            prevIndex === catItemList.length - 1 ? 0 : prevIndex + 1
          )
        }
      >
        <span
          className="carousel-control-next-icon bg-info rounded-5 "
          aria-hidden="true"
        ></span>
        <span className="visually-hidden">Next</span>
      </button>

      {/* <div className="carousel-indicators " style={{ overflowX: "auto" }}>
        {catItemList?.map((it, index) => (
          <BannerIndicatorButton
            key={index}
            index={index}
            item={it}
            active={index === activeIndex}
            onClick={() => setActiveIndex(index)}
          />
        ))}
      </div> */}
    </div>
  );
}

export default Banner;

