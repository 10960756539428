
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";

const YouTubecard = ({ item, navigate, showEdit }) => {
  return (
    <div>
      <div class="mb-3 fw-semibold">
        <table>
          <td>
            <h3>{item.title}</h3>
          </td>

          <div class="d-grid gap-2 d-md-flex justify-content-md-end px-5 pt-5">
            <div className="">
              {showEdit ? (
                <div>
                  <button
                    onClick={() =>
                      navigate(
                        "products",

                        localStorage.setItem("myData", JSON.stringify(item))
                      )
                    }
                    type="button"
                    class="btn btn-primary me-md-2"
                  >
                    View all
                  </button>
                  <button
                    className="btn btn-primary "
                    onClick={() =>
                      navigate(
                        "addcat",
                        localStorage.setItem("catData", JSON.stringify(item))
                      )
                    }
                    type="submit"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                    Edit
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </table>
      </div>
      {/* <div className="row row-cols-md-2  row-cols-lg-3  row-cols-xl-4 g-1  mb-2"> */}
      <div className="d-flex flex-row flex-nowrap overflow-auto gap-2">
        {item?.items?.map((it) => (
          <div
            class="card rounded-4 border-5   bg-transparent "
            style={{ minWidth: 220, minHeight: 150, width: 300 }}
          >
            <iframe
              title="{it?.iconURL}"
              height="220"
              src={it?.iconURL}
              // src="https://drive.google.com/file/d/1pBUsRGvPxIpEjWjY3q12bUwPQZgQ5TfA/preview"

              allow="autoplay"
              allowFullScreen
              className="rounded-4 d-block"
              loading="lazy"
            ></iframe>

            <div class="card-body ">
              <h5 class="card-title">{it?.title}</h5>
              <p class="card-text">{it?.subTitle}</p>
              {showEdit ? (
                <Link
                  to={"/productdetail/" + it?.itemId}
                  onClick={() =>
                    localStorage.setItem("myProduct", JSON.stringify(it))
                  }
                >
                  <button class="btn btn-primary ">Video Edit</button>
                </Link>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default YouTubecard;
