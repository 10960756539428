import { Link, useNavigate } from "react-router-dom";
import ProfileService from "./ProfileService";
import PropTypes from "prop-types";
import { USER_INFO } from "../Constant";
import { useState, useEffect } from "react";
import swal from "sweetalert";

import { SESSION_ID, CUSTOMER_ID, PAYPAL_CLIENT_ID , DEBUG_LOG_ENABLED,} from "../Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function Profile (props) {
  const [data, setData] = useState({});
  const [profile, setProfile] = useState("false");
  const changeProfile = () => {
    let val = profile;
    if (val === "false") {
      setProfile("true");
    } else {
      setProfile("false");
    }
  };

  const userData = localStorage.getItem(USER_INFO);
  const profiledata = JSON.parse(userData);

  let [authMode, setAuthMode] = useState("signin");

  const changeAuthMode = () => {
    setAuthMode(authMode === "signin" ? "signup" : "signin");
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState({
    customerId: profiledata?.customerId,
    name: profiledata?.name,
    emailId: profiledata?.emailId,
    mobileNo: profiledata?.mobileNo,
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setCustomer({ ...customer, [e.target.name]: value });
  };

  const saveCustomer = (e) => {
    e.preventDefault();
    if (
      customer.name === "" ||
      customer.emailId === "" ||
      customer.mobileNo === ""
    ) {
      swal(" Please enter all fields ");
    } else {
      const customerData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            name: customer?.name,
            emailId: customer?.emailId,
            mobileNo: customer?.mobileNo,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      ProfileService.saveCustomer(customerData)
        .then((response) => {
          swal("successful : " + response.data);
          if (DEBUG_LOG_ENABLED) {
            console.log(response.data);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/login");
          } else {
            swal("data : " + err);
            if (DEBUG_LOG_ENABLED) {
              console.log(err);
            }
          }
        });
    }
  };


    // const DeleteAccount = async () => {
    //   try {
    //     const itemData = JSON.stringify({
    //       pubInfo: {
    //         sessionId: localStorage.getItem(SESSION_ID),
    //       },
    //       request: {
    //         busiParams: {
    //           customerId: localStorage.getItem(CUSTOMER_ID),
    //         },
    //         isEncrypt: false,
    //         transactionId: "897987987989",
    //       },
    //     });
    //     if (DEBUG_LOG_ENABLED) {
    //       console.log("DeleteAccount : " + itemData);
    //     }

    //     let response = await axios.post(BASE_URL + deleteCustomer, itemData, {
    //       headers: {
    //         "Access-Control-Allow-Origin": "*",
    //         "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
    //         "Access-Control-Allow-Headers": "Content-Type",
    //         "Content-Type": "application/json",
    //         Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
    //       },
    //     });

    //     swal(response.data.respData.message);
    //     localStorage.setItem(CUSTOMER_ID, " ");
    //     localStorage.setItem(ACCESS_TOKEN, " ");
    //     localStorage.setItem(USER_LOGIN, " ");
    //     dispatch(handleLoggin(false));
    //     dispatch(increment(0));
    //     navigate("/login");
    //   } catch (err) {
    //     if (err.response.status === 401) {
    //       localStorage.setItem(CUSTOMER_ID, "");
    //       navigate("/login");
    //     } else {
    //       swal("data : " + err);
    //       if (DEBUG_LOG_ENABLED) {
    //         console.log(err);
    //       }
    //     }
    //   }
    // };

  return (
    <div className="align-items-center   py-5  mt-5">
      <div className="d-flex flex-column  align-items-center">
        <div className="card p-5">
          {profile === "true" ? (
            <>
              <div
                className="Auth-form-title   "
                style={{ alignSelf: "center" }}
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-user-pen"
                  style={{ height: "70px" }}
                />
              </div>
              <Link onClick={changeProfile}>
                <h3 className="Auth-form-title ms-5">
                  Edit User Profile
                  <small className="ps-2 ms-4  ">
                    <FontAwesomeIcon icon="fa-solid fa-pen-to-square " />
                  </small>
                </h3>
              </Link>
           
              <div className="form-group mt-3">
                <label>Full Name</label>
                <input
                  type="text"
                  name="name"
                  value={customer?.name}
                  onChange={(e) => handleChange(e)}
                  className="form-control mt-1"
                  placeholder="e.g Ram Kumar"
                />
              </div>
              <div className="form-group mt-3">
                <label>Mobile Number</label>
                <input
                  type="text"
                  name="mobileNo"
                  value={customer?.mobileNo}
                  onChange={(e) => handleChange(e)}
                  className="form-control mt-1"
                  placeholder="Mobile Number"
                />
              </div>
              <div className="form-group mt-3">
                <label>Email address</label>
                <input
                  type="email"
                  name="emailId"
                  value={customer?.emailId}
                  onChange={(e) => handleChange(e)}
                  className="form-control mt-1"
                  placeholder="Email Address"
                />
              </div>
              <div className="text-left pt-3">
                Not added address yet?
                <span> </span>
                <Link to={"/addaddress"}>Add address</Link>
              </div>
              <div className="d-grid gap-2 mt-3">
                <button
                  onClick={saveCustomer}
                  type="submit"
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>
              <div className="d-grid gap-2 mt-5">
                <button
                  // onClick={saveCustomer}
                  type="button"
                  class="btn btn-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  Account Delete
                </button>
              </div>

              <div
                class="modal fade"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="text-center" id="staticBackdropLabel">
                        Account Delete
                      </h4>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      You are account permanent delete...
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary px-5"
                        data-bs-dismiss="modal"
                      >
                        No
                      </button>
                      <button
                       // onClick={() => DeleteAccount()}
                        type="button"
                        class="btn btn-danger px-5"
                        data-bs-dismiss="modal"
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className="Auth-form-title  "
                style={{ alignSelf: "center" }}
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-user"
                  style={{ height: "70px" }}
                />
              </div>
              <h3 className="Auth-form-title ps-5 ms-5">
                User Profile
                <small className="ps-5 ms-3  ">
                  <Link onClick={changeProfile}>
                    <FontAwesomeIcon icon="fa-solid fa-pen-to-square " />
                  </Link>
                </small>
              </h3>
              <div className="form-group mt-3 row">
             
              </div>
              <div className="form-group mt-3 row">
                <div className="col">
                  <h5>Full Name </h5>
                </div>
                <div className="col">
                  <h5>{customer?.name}</h5>
                </div>
              </div>
              <div className="form-group mt-3 row">
                <div className="col">
                  <h5>Mobile No.</h5>
                </div>
                <div className="col">
                  <h5>{customer?.mobileNo}</h5>
                </div>
              </div>
              <div className="form-group mt-3 row">
                <div className="col">
                  <h5>Email </h5>
                </div>
                <div className="col">
                  <p>{customer?.emailId}</p>
                </div>
              </div>
              {/* <div className="form-group mt-3">
                    <label>Full Name</label>
                    <input
                      type="text"
                      name="name"
                      value={customer.name}
                     

                      className="form-control mt-1"
                      placeholder="e.g Ram Kumar"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Mobile Number</label>
                    <input
                      type="text"
                      name="mobileNo"
                      value={customer.mobileNo}
                      // onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                      placeholder="Mobile Number"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label>Email address</label>
                    <input
                      type="email"
                      name="emailId"
                      value={customer.emailId}
                      // onChange={(e) => handleChange(e)}
                      className="form-control mt-1"
                      placeholder="Email Address"
                    />
                  </div> */}
              <div className="text-left pt-3">
                Not added address yet?
                <span> </span>
                <Link to={"/addaddress"}>Add address</Link>
              </div>
              {/* <div className="d-grid gap-2 mt-3">
                    <button
                      onClick={saveCustomer}
                      type="submit"
                      className="btn btn-primary"
                    >
                      Submit
                    </button>
                  </div> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
