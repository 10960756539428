import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="vstack mt-auto">
      <footer className="mt-auto py-5 bg-info">
        <div className="container d-flex justify-content-center">
          <div className="row g-3">
            <div className="col-md-6 col-lg-4 d-none d-md-block">
              <h5 className="text-light">Contact us</h5>
              <div className="vstack gap-1">
                <p className="mb-2 text-light text-opacity-75 small">
                  Shree Sarv Shakti is a leading Uttar Pradesh, India. based
                  Astrologer, Numerologist, Palmist and Vaastu Consultant
                </p>
                <small className="d-flex text-light text-opacity-75 gap-2">
                  <FontAwesomeIcon
                    icon={["fas", "map-marker"]}
                    className="mt-1"
                  />
                  <div>
                    Address shree saccha baba nagar arail naini prayagraj uttar
                    pradesh Pine code 211008
                  </div>
                </small>
                <small className="d-flex text-light text-opacity-75 gap-2">
                  <FontAwesomeIcon
                    icon={["fas", "envelope"]}
                    className="mt-1"
                  />
                  <div>shaktishreesarv@gmail.com</div>
                </small>
                <small className="d-flex text-light text-opacity-75 gap-2">
                  <FontAwesomeIcon icon={["fas", "phone"]} className="mt-1" />
                  <div>(+91) 7985082508</div>
                </small>
              </div>
            </div>
            <div className="col-md-6 col-lg-2 d-none d-md-block">
              <h5 className="text-light">Information</h5>
              <div className="vstack small gap-2">
                <Link to="/about">
                  <a className="footer-link text-light">About us</a>
                </Link>
                <Link to="/contact">
                  <a className="footer-link text-light">Contact us</a>
                </Link>
                <Link to="/termsandconditions">
                  <a className="footer-link text-light">TermsAndConditions</a>
                </Link>
                <Link to="/privacypolicy">
                  <a className="footer-link text-light">privacy policy</a>
                </Link>
                <Link to="/refundPolicy">
                  <a className="footer-link text-light">Refund Policy</a>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-2 d-none d-md-block">
              <h5 className="text-light text-light ">Account</h5>
              <div className="vstack small gap-2">
                <Link to="/login">
                  <a className="footer-link text-light">User Login</a>
                </Link>
                <Link to="/signup">
                  <a className="footer-link text-light ">User Register</a>
                </Link>
                <Link to="/profile">
                  <a className="footer-link text-light ">Account Setting</a>
                </Link>
                <Link to="/orderhistory">
                  <a className="footer-link text-light ">My Orders</a>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <h5 className="text-light text-center text-md-start">
                Newsletter
              </h5>
              <div className="text-light text-opacity-75 mb-3 small text-center text-md-start">
                Subscribe for promotions and wonderful events
              </div>
              <form className="hstack gap-2 justify-content-center justify-content-md-start mb-3">
                <div>
                  <input
                    type="email"
                    className="form-control border border-primary"
                    placeholder="Your email"
                    maxlength="50"
                  />
                </div>
                <button className="btn btn-warning">Subscribe</button>
              </form>
              <div className="hstack gap-2 justify-content-center justify-content-md-start">
                <a href="#" className="text-decoration-none">
                  <img src="/images/apple-app-store-badge.svg" alt="" />
                </a>
                <a href="#" className="text-decoration-none">
                  <img src="/images/google-play-badge.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer className="py-4 bg-primary">
        <div className="container d-flex">
          <span className="text-white  my-auto">
            &copy; {new Date().getFullYear()} Powered by Dhakad Innovations Pvt
            Ltd.
          </span>
          <div className="ms-auto hstack gap-4">
            <a
              href="https://www.facebook.com/profile.php?id=100086725784050"
              className="ms-auto link-light"
            >
              <FontAwesomeIcon icon={["fab", "facebook-f"]} size="lg" />
            </a>
            <a
              href="https://twitter.com/ShreeSarvShakt1"
              className="ms-auto link-light"
            >
              <FontAwesomeIcon icon={["fab", "twitter"]} size="lg" />
            </a>
            <a
              href="https://www.instagram.com/shreesarvshakti/"
              className="ms-auto link-light"
            >
              <FontAwesomeIcon icon={["fab", "instagram"]} size="lg" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
