function LoginScrollpages() {
  return (
    <div className=" col-md-6 col-lg-6 col-xl-6 bg-primary px-0 d-none d-md-block">
      <img
        src="./../logo192.png"
        style={{ width: 80 }}
        class="img-fluid "
        alt="appLogo"
      ></img>

      <div className=" card-body ">
        <div
          id="carouselExampleSlidesOnly"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="d-flex flex-column  align-items-center">
                {/* <img
                  src="https://drive.google.com/uc?id=1M8o-hJ4fzFJ7A6Yau4ENTqcBNG7n07kc"
                  className="d-block  "
                  style={{ height: 300 }}
                  alt="..."
                /> */}
                <img
                  referrerpolicy="origin"
                  // src="https://drive.google.com/uc?id=1M8o-hJ4fzFJ7A6Yau4ENTqcBNG7n07kc"
                  src="https://shreesarvshakti.com/img/Daco_39350.png"
                  alt="..."
                  className="d-block  "
                  style={{ height: 300 }}
                ></img>
              </div>

              <p className="pt-2 fw-bold   text-white">
                <span className="h4 fw-bold">Why Sign Up?</span>
                <br></br>
                <ul style={{ listStyleType: "disc" }}>
                  <li>Get personalized information</li>
                  <li> Save charts (kundli) on cloud</li>
                  <li>Write your notes & comments</li>
                  <li>Anywhere access: mobile & web</li>
                  <li>Access worksheet for analysis</li>
                </ul>
              </p>
            </div>
            {/* <div className="carousel-item ">
              <img
                src="https://drive.google.com/uc?id=1XfTSPej4NE0AUcm96XTfYmUHqcl5-D3c"
                className="d-block pt-5 "
                alt="..."
              />
              <p className="pt-3 fw-bold h-3 text-white">
                Video consult top doctors from with your comfort
              </p>
            </div>
            <div className="carousel-item active">
              <img
                src="https://drive.google.com/uc?id=1-PyrNinpmmJirBW37MqEBN2A5yUxB1hb"
                className="d-block pt-5 "
                alt="..."
              />
              <p className="pt-3 fw-bold h-3 text-white">
                Video consult top doctors from with your comfort
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginScrollpages;
