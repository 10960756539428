import Banner from "./Banner";
import FeatureProduct from "./FeatureProduct";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, createContext } from "react";

import ProductListCard from "./ProductListCard";
import ProductGridCard from "./ProductGridCard";
import {
  ROLE,
  USER_ROLE,
  TOP_BANNER,
  TOP_PRODUCT,
  CARD_VIEW,
  ABOUT_US_VIEW,
  you_tube_view,
  CART_COUNT
} from "../Constant";
import ProductItemCart from "../landing/ProductItemCart";
import AboutUS from "../about/AboutUs";
import YouTubecard from "./YouTubecard";
import InstagramPosts from "./InstagramPosts";
import Googlereview from "./Googlereview";
import { handleLoggin, increment } from "../actions";
import { useDispatch } from "react-redux";

function Landing({ listData }) {
  const navigate = useNavigate();
  let [showEdit, setShowEdit] = useState(false);
  // const bannerItemRemoved = listData.splice(0, 1);

  // useEffect(() => {
  //   const userRole = localStorage.getItem(USER_ROLE);
  //   if (userRole?.includes(ROLE)) {
  //     setShowEdit(true);
  //   }
  // }, []);
  const dispatch = useDispatch();

  useEffect(() => {
  
    const userRole = localStorage.getItem(USER_ROLE);
    const cartCount = localStorage.getItem(CART_COUNT);

    if (userRole) {
      dispatch(handleLoggin(true));
    }

    if (cartCount) {
    
      dispatch(increment(cartCount));
    }

    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
    }
  
  }, []);
  // listData;
  return (
    <>
      {/* <div class="position-absolute top-100 start-100 translate-middle">
        <button>
          <FontAwesomeIcon
            icon="fa-brands fa-whatsapp"
            style={{ width: 50, height: 50 }}
          />
        </button>
      </div> */}

      {/* <ScrollToTopOnMount /> */}

      {listData?.map((item, index) => (
        <div className=" px-3" style={{ overflow: "hidden" }}>
          {
            <>
              {
                <div>
                  {item?.viewType === TOP_BANNER ? (
                    <Banner product={item} showEdit={showEdit} />
                  ) : (
                    <div>
                      <div>
                        {item?.viewType === TOP_PRODUCT ? (
                          <div>
                            <ProductGridCard
                              item={item}
                              navigate={navigate}
                              showEdit={showEdit}
                              nama={TOP_PRODUCT}
                            />
                          </div>
                        ) : (
                          <div>
                            <div>
                              {item?.viewType === CARD_VIEW ? (
                                <div>
                                  <ProductListCard
                                    item={item}
                                    showEdit={showEdit}
                                  />
                                </div>
                              ) : (
                                <div>
                                  {item?.viewType === ABOUT_US_VIEW ? (
                                    <div>
                                      {showEdit ? (
                                        <div>
                                          <ProductListCard
                                            item={item}
                                            showEdit={showEdit}
                                          />
                                        </div>
                                      ) : (
                                        <div></div>
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      {item?.viewType === you_tube_view ? (
                                        <div>
                                          {showEdit ? (
                                            <div>
                                              <YouTubecard
                                                item={item}
                                                showEdit={showEdit}
                                                navigate={navigate}
                                              />
                                            </div>
                                          ) : (
                                            <div></div>
                                          )}
                                        </div>
                                      ) : (
                                        <div>
                                          {item?.viewType ===
                                          "InstagramPosts" ? (
                                            <div>
                                              <div>
                                                {/* <InstagramPosts
                                                  item={item}
                                                  showEdit={showEdit}
                                                  navigate={navigate}
                                                /> */}
                                                {/* <MagicScroll
                                                  images={images}
                                                  speed={2400}
                                                  autoplay={1000}
                                                  items={4}
                                                /> */}
                                              </div>
                                            </div>
                                          ) : (
                                            <div>
                                              {item?.viewType ===
                                              "Googlereview" ? (
                                                <div>
                                                  <div>
                                                    <Googlereview
                                                      item={item}
                                                      showEdit={showEdit}
                                                      navigate={navigate}
                                                    />
                                                  </div>
                                                </div>
                                              ) : (
                                                <div></div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              }
            </>
          }
        </div>
      ))}
    </>
  );
}

export default Landing;
