import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ABOUT_US_VIEW, BASE_URL_IMG } from "../Constant";
import parse from "html-react-parser";

const AboutUs = () => {
  const catData = localStorage.getItem("categorydata");
  const product = JSON.parse(catData);
  let [showEdit, setShowEdit] = useState(false);

  useEffect(() => {}, []);
  const navigate = useNavigate();

  return (
    <>
      <div className="container ">
        {
          <div className="container py-5">
            {product[0]?.items?.map((it) => (
              <div class="card mb-3 ">
                <div class="row g-0 text-left">
                  <div class="col-md-4">
                    <Link
                      to={"/productdetail/" + product.itemId}
                      href="!#"
                      onClick={() =>
                        navigate(
                          "/productdetail" + "/" + product.itemId,
                          localStorage.setItem(
                            "myProduct",
                            JSON.stringify(product)
                          )
                        )
                      }
                    >
                      <img
                        src={BASE_URL_IMG+it.iconURL}
                        class="img-fluid rounded-start"
                        alt="..."
                      />
                    </Link>
                  </div>
                  <div class="col-md-8 ">
                    <div class="card-body">
                      <h5 class="card-title">{parse(`${it.title}`)}</h5>
                      <p class="card-text  mt-5">{parse(`${it.subTitle}`)}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        }
      </div>
    </>
  );
};

export default AboutUs;
