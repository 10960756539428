import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductItemCart from "./ProductItemCart";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import {
  ROLE,
  USER_ROLE,
  TOP_BANNER,
  TOP_PRODUCT,
  CARD_VIEW,
  ABOUT_US_VIEW,
  MYDATA,
  MYPRODUCT,
  CATDATA,
  BASE_URL_IMG
} from "../Constant";

const ProductListCard = ({ item, showEdit }) => {
  const navigate = useNavigate();
  return (
    <div>
      {
        <div>
          <div class="mb-3 fw-semibold">
            <table>
              <td>
                <h3>{item?.title}</h3>
              </td>

              <div class="d-grid gap-2 d-md-flex justify-content-md-end px-5 pt-5">
                <div className="">
                  {showEdit ? (
                    <div>
                      <button
                        onClick={() =>
                          navigate(
                            "products" + "/",
                            localStorage.setItem(MYDATA, JSON.stringify(item))
                          )
                        }
                        type="button"
                        class="btn btn-primary me-md-2"
                      >
                        View all
                      </button>
                      <button
                        className="btn btn-primary "
                        onClick={() =>
                          navigate(
                            "addcat" + "/",
                            localStorage.setItem(CATDATA, JSON.stringify(item))
                          )
                        }
                        type="submit"
                      >
                        <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                        Edit
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </table>
          </div>
          <div>
            {item?.items?.map((it) => (
              <div class="card mb-3 bg-transparent ">
                <div class="row g-0 ">
                  <div class="col-md-4">
                    {showEdit ? (
                      <div>
                        <Link
                          to={"/productdetail/" + it?.itemId}
                          onClick={() =>
                            navigate(
                              "/productdetail/" + it?.itemId,
                              localStorage.setItem(
                                MYPRODUCT,
                                JSON.stringify(it)
                              )
                            )
                          }
                        >
                          <img
                            class="img-fluid rounded-start"
                            src={BASE_URL_IMG + it.iconURL}
                            alt="Girl in a jacket"
                          />
                        </Link>
                      </div>
                    ) : (
                      <div className="col-10 ms-2 justify-content-center">
                        <img
                          class="img-fluid rounded-start "
                          src={BASE_URL_IMG + it.iconURL}
                          alt="Girl in a jacket"
                        ></img>
                      </div>
                    )}
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title ">{parse(`${it.title}`)}</h5>
                      {/* {parse(`${it?.subTitle}`)} */}
                      <p class="card-text  mt-2">{parse(`${it.subTitle}`)}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  );
};

export default ProductListCard;
