import Ratings from "react-ratings-declarative";
import { Link } from "react-router-dom";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount";
import { useState, useEffect, createContext } from "react";
import {
  ACCESS_TOKEN,
  CUSTOMER_ID,
  SESSION_ID,
  BASE_URL,
  ADD_TO_CART,
  CATITEM,
  DEBUG_LOG_ENABLED,
  getCategoryItemByItemId,
  DEEPLINK_URL,
  BASE_URL_IMG,
} from "../../Constant";
import swal from "sweetalert";

import { useSelector, useDispatch } from "react-redux";
import { increment, decrement, handleLoggin } from "../../actions";
import { useNavigate } from "react-router-dom";
// import CartService from "../../services/CartService";
// import FeatureProduct from "../../landing/FeatureProduct";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ROLE, USER_ROLE } from "../../Constant";
import axios from "axios";
import { useParams } from "react-router-dom";

const iconPath =
  "M18.571 7.221c0 0.201-0.145 0.391-0.29 0.536l-4.051 3.951 0.96 5.58c0.011 0.078 0.011 0.145 0.011 0.223 0 0.29-0.134 0.558-0.458 0.558-0.156 0-0.313-0.056-0.446-0.134l-5.011-2.634-5.011 2.634c-0.145 0.078-0.29 0.134-0.446 0.134-0.324 0-0.469-0.268-0.469-0.558 0-0.078 0.011-0.145 0.022-0.223l0.96-5.58-4.063-3.951c-0.134-0.145-0.279-0.335-0.279-0.536 0-0.335 0.346-0.469 0.625-0.513l5.603-0.815 2.511-5.078c0.1-0.212 0.29-0.458 0.547-0.458s0.446 0.246 0.547 0.458l2.511 5.078 5.603 0.815c0.268 0.045 0.625 0.179 0.625 0.513z";

function ProductDetail(props) {
  function changeRating(newRating) {}
  const myData = localStorage.getItem("myProduct");
  const itemList = JSON.parse(myData);
  //  const[product, setProduct]= useState({});
  const counter = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogged = useSelector((state) => state.isLogged);

  const [item, setItem] = useState(itemList);
   const [priceInDollar, setpriceInDollar] = useState('');
    const [panditcount, setpanditcount] = useState('');
    



  let [discription, setDiscription] = useState(
    "Something went wrong please try after some time."
  );

    let [itemPrice, setitemPrice] = useState();
  const addToMycart = () => {
    // e.preventDefault();
    const id = localStorage.getItem(CUSTOMER_ID);
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (DEBUG_LOG_ENABLED) {
      console.log("CartService : id:" + id + "  token:" + token);
    }
    if (isLogged && id != null && id != "") {
      addToCart();
    } else {
      navigate("/login");
    }
  };
  const addToCart = async () => {
    if (itemPrice) {
       try {
         const itemData = JSON.stringify({
           pubInfo: {
             sessionId: localStorage.getItem(SESSION_ID),
           },
           request: {
             busiParams: {
               id: 0,
               customerId: localStorage.getItem(CUSTOMER_ID),
               itemId: item.itemId,
               // price: item.price,
               price: itemPrice,
               panditCount: JSON.parse(priceInDollar),

               //  panditCount: 0,
             },
             isEncrypt: false,
             transactionId: "897987987989",
           },
         });
         if (DEBUG_LOG_ENABLED) {
           console.log("CartService : " + itemData);
         }

         // const response = await CartService.addToCart(itemData);

         let response = await axios.post(BASE_URL + ADD_TO_CART, itemData, {
           headers: {
             "Access-Control-Allow-Origin": "*",
             "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
             "Access-Control-Allow-Headers": "Content-Type",
             "Content-Type": "application/json",
             Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
           },
         });

         //dispatch({type:ADD_CART, payload: 10})
         dispatch(increment(response?.data?.respData?.respMsg?.cartCount));
         swal(response?.data?.respData?.message);
       } catch (err) {
         if (err.response.status == 401) {
           localStorage.setItem(CUSTOMER_ID, "");
           navigate("/login");
         } else {
           swal("data : " + err);
           if (DEBUG_LOG_ENABLED) {
             console.log(err);
           }
         }
       }
    }else{
      swal("please select the Pooja")
    }
   
  };

  const Params = useParams();
  const item_id = Params?.id;
  //console.log(Params);

  const getCategoryItem = async () => {
    try {
      const ItemData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            itemId: item_id,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(
        BASE_URL + getCategoryItemByItemId,
        ItemData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
          },
        }
      );

      setItem(response.data?.respData?.respMsg?.item);
    } catch (error) {
      console.log(error);
      swal(error);
    }
  };

  let [showEdit, setShowEdit] = useState(false);

  const fileContent = () => {
    axios
      .create({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers":
            "Content-Type,X-Auth-Token, Origin, Authorization",
        },
      })

      .then(function (response) {
        setDiscription(response?.data);
      })
      .catch((error) => {
        swal("error : " + error);
        if (DEBUG_LOG_ENABLED) {
          console.log(error);
        }
      });
  };

  useEffect(() => {
    if (itemList === null) {
      getCategoryItem();
    } else if (item_id != itemList?.itemId) {
      getCategoryItem();
    }

    // getCategoryItem();
    //fileContent();
    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
    }
  }, []);
  let [hindi, setHindi] = useState(true);
  const changeHindi = () => {
    setHindi(false);
  };
  const changeEnglish = () => {
    setHindi(true);
  };
  const changeImage = () => {};

  // description;
  // const description = JSON.stringify(item?.description);
  // const newDescription = JSON.parse(item.description);
// console.log(
//  newDescription.price +
//     "--------------------------------------------------------0000000000000"
// );


 const handleAddressIdchang = (e, item) => {
   const value = e.target.value;

    const price = item.price;
     const priceInDollar = item.priceInDoller;
      const panditcount = item.days;


  // console.log(item);
  setpanditcount(panditcount);
  setpriceInDollar(priceInDollar);
   setitemPrice(value);
 };
  return (
    <div className=" mt-5 py-4 px-xl-5">
      <ScrollToTopOnMount />
      <nav aria-label="breadcrumb" className="bg-custom-light rounded mb-4">
        <ol className="breadcrumb p-3">
          <li className="breadcrumb-item">
            <Link className="text-decoration-none link-secondary">
              {item?.title}
            </Link>
          </li>
        </ol>
      </nav>
      <div className="row mb-4">
        <div className="d-none d-lg-block col-lg-1">
          <div className="image-vertical-scroller">
            <div className="d-flex flex-column">
              {Array.from({ length: 6 }, (_, i) => {
                let selected = i !== 1 ? "opacity-6" : "";
                return (
                  <a key={i}>
                    <img
                      className={"rounded mb-2 ratio " + selected}
                      alt=""
                      onClick={changeImage}
                      src={BASE_URL_IMG + item?.iconURL}
                    />
                  </a>
                );
              })}
            </div>
          </div>
        </div>

        <div className="col-lg-5">
          <div className="row">
            <div className="col-12 mb-4 ">
              <img
                className="border rounded ratio ratio-1x1"
                alt=""
                src={BASE_URL_IMG + item?.iconURL}
                height={500}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="d-flex flex-column h-100">
            <div className="d-flex">
              <div className="d-inline h5 mb-0 fw-semibold me-3">
                {item?.title}
              </div>
              <div className="ms-auto">
                <button
                  type="button"
                  class="btn btn-Primary"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Copy"
                  onClick={() =>
                    navigator.clipboard.writeText(DEEPLINK_URL + item_id)
                  }
                >
                  <FontAwesomeIcon
                    icon="fa-sharp fa-light fa-share-nodes"
                    beat
                  />
                </button>
              </div>
            </div>
            <div className="vstack">
              <div className="d-flex mb-3 gap-2">
                <Ratings
                  rating={4.5}
                  widgetRatedColors="rgb(253, 204, 13)"
                  changeRating={changeRating}
                  widgetSpacings="2px"
                >
                  {Array.from({ length: 5 }, (_, i) => {
                    return (
                      <Ratings.Widget
                        key={i}
                        widgetDimension="20px"
                        svgIconViewBox="0 0 19 20"
                        svgIconPath={iconPath}
                        widgetHoverColor="rgb(253, 204, 13)"
                      />
                    );
                  })}
                </Ratings>
              </div>
              {/* {JSON.parse(item.description).map((item) => (
                <h1>{item}</h1>
              ))} */}

              <div>
                {" "}
                {item?.description ? (
                  <div class="row row-cols-2 ">
                    {JSON.parse(item?.description).map((item) => (
                      <div class="col mt-2 ">
                        <div class="">
                          <div class="card bg-transparent ">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-sm-1 ">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id={item?.price}
                                    value={item?.price}
                                    onChange={(e) =>
                                      handleAddressIdchang(e, item)
                                    }
                                  ></input>
                                </div>
                                <div class="col-10 ">
                                  <h5>
                                    {" "}
                                    {"₹" +
                                      item?.price +
                                      "/- " +
                                      "" +
                                      "$" +
                                      item?.priceInDoller}{" "}
                                  </h5>
                                  <p class="card-title">
                                    {item?.days} <br></br>
                                    {item?.priests}
                                    <br></br>
                                    {item.chants}
                                  </p>
                                </div>
                              </div>

                              {/* <p class="card-text">{parse(`${item?.viewMoreTitle}`)}</p> */}
                              <p class="card-text">
                                {/* {parse(`${newDescription.map((item)=>{   <p> {item.priceInDoller}</p>})}`)} */}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}{" "}
              </div>

              {/* <div className="my-5">
                <h5> {parse(`${item?.viewMoreTitle}`)}</h5>
                <h4 className="text-muted  pt-2">
                  {"₹" + item?.price + "/- " + "" + "$" + item?.priceInDollar}
                </h4>
              </div> */}
              <div className="row g-3 mt-3 mb-4">
                <div className="col">
                  <button
                    className="btn btn-outline-primary py-2 w-100"
                    onClick={addToMycart}
                  >
                    Add to cart
                  </button>
                </div>
                {showEdit ? (
                  <Link
                    className="btn btn-outline-primary mt-3"
                    to={"/addcatitem"}
                    replace
                    onClick={() =>
                      localStorage.setItem(CATITEM, JSON.stringify(item))
                    }
                    type="submit"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-pen-to-square" /> Edit
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mb-4">
          <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-3"></div>
        </div>
      </div>

      <div className="container ">
        <div className="row g-3">
          <div className="col-lg-12 ">
            <div className="card border-2 shadow-sm bg-transparent ">
              <div
                className="px-3 d-flex border-bottom overflow-auto "
                style={{ height: 70 }}
              >
                <ul className="nav nav-pills my-auto flex-nowrap">
                  <li className="nav-item">
                    <button
                      onClick={changeEnglish}
                      className="btn btn-outline-primary "
                    >
                      English
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      onClick={changeHindi}
                      className="btn btn-outline-primary ms-4"
                    >
                      Hindi
                    </button>
                  </li>
                </ul>
              </div>
              <div
                data-bs-spy="scroll"
                data-bs-target="#navbar-example2"
                data-bs-root-margin="0px 0px -40%"
                data-bs-smooth-scroll="true"
                class="scrollspy-example bg-light p-3 rounded-2 bg-transparent "
                bindex="0"
              >
                {hindi ? (
                  <div className="card-body ">
                    <small>{parse(`${item?.subTitle}`)}</small>
                    <hr />
                  </div>
                ) : (
                  <p className="lead flex-shrink-0">
                    <small>{parse(`${item?.subTitleID}`)}</small>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;
