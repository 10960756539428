import { Link } from "react-router-dom";
import React, { useState, useContext } from "react";
import CartService from "../services/CartService";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Ratings from "react-ratings-declarative";
import swal from "sweetalert";

import {
  ACCESS_TOKEN,
  CUSTOMER_ID,
  SESSION_ID,
  BASE_URL,
  ADD_TO_CART,
  MYPRODUCT,
  DEBUG_LOG_ENABLED,
  BASE_URL_IMG,
} from "../Constant";

import { useSelector, useDispatch } from "react-redux";
import { increment } from "../actions";
import axios from "axios";

const iconPath =
  "M18.571 7.221c0 0.201-0.145 0.391-0.29 0.536l-4.051 3.951 0.96 5.58c0.011 0.078 0.011 0.145 0.011 0.223 0 0.29-0.134 0.558-0.458 0.558-0.156 0-0.313-0.056-0.446-0.134l-5.011-2.634-5.011 2.634c-0.145 0.078-0.29 0.134-0.446 0.134-0.324 0-0.469-0.268-0.469-0.558 0-0.078 0.011-0.145 0.022-0.223l0.96-5.58-4.063-3.951c-0.134-0.145-0.279-0.335-0.279-0.536 0-0.335 0.346-0.469 0.625-0.513l5.603-0.815 2.511-5.078c0.1-0.212 0.29-0.458 0.547-0.458s0.446 0.246 0.547 0.458l2.511 5.078 5.603 0.815c0.268 0.045 0.625 0.179 0.625 0.513z";
function changeRating(newRating) {}
function FeatureProduct({ product }) {
  const dispatch = useDispatch();
  //const {state, dispatch} = useContext(UserContext);
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const isLogged = useSelector((state) => state.isLogged);
    const language = useSelector((state) => state.Language);

  //  const[ panditcount , setpanditcount]=useState();
  //   const [priceInDollar ,setpriceInDollar]=useState();
  //   const[ newitemPrice ,setnewitemPrice]=useState();

  const itemPrice = 0;

  const addToMycart = (newitemPrice, priceInDollar) => {
    // e.preventDefault();
    const id = localStorage.getItem(CUSTOMER_ID);
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (DEBUG_LOG_ENABLED) {
      console.log("CartService : id:" + id + "  token:" + token);
    }
    if (isLogged && id != null && id != "" && token != "") {
      addToCart(newitemPrice, priceInDollar);
    } else {
      navigate("/login");
    }
  };
  const addToCart = async (newitemPrice, priceInDollar) => {
    try {
      const itemData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            id: 0,
            customerId: localStorage.getItem(CUSTOMER_ID),
            itemId: product.itemId,
            // price: product.price,
            // panditCount:0,
            price: newitemPrice,
            panditCount: priceInDollar,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      if (DEBUG_LOG_ENABLED) {
        console.log("CartService : " + itemData);
      }

      //const response = await CartService.addToCart(itemData);
      let response = await axios.post(BASE_URL + ADD_TO_CART, itemData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });

      dispatch(increment(response.data.respData.respMsg.cartCount));
      //dispatch(increment(response.data.respData.respMsg.cartCount));
      swal(response.data.respData.message);
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.setItem(CUSTOMER_ID, "");
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };
  const handleAddressIdchang = (e, item) => {
    {
      JSON.parse(product?.description).map((item, index) => {
        if (itemPrice === index) {
          addToMycart(item.price, item.priceInDoller);
        }
      });
    }

    // const value = e.target.value;

    // const price =    e.target.item.price;
    // const priceInDollar = e.target.item.priceInDoller;
    // const panditcount = e.target.item.days;

    // console.log(item);
    // setpanditcount(panditcount);
    // setpriceInDollar(priceInDollar);
    // setnewitemPrice(price);
  };
  return (
    // <div className="card h-100 border-0 shadow-sm">
    <div
      className="card shadow-sm me-3 bg-transparent"
      style={{ minWidth: 120, minHeight: 100, width: 300 }}
    >
      <div className=" ratio-1x1">
        <Link
          to={"/productdetail/" + product.itemId}
          onClick={() =>
            navigate(
              "/productdetail/" + product.itemId,
              localStorage.setItem("myProduct", JSON.stringify(product))
            )
          }
        >
          <img
            className="card-img-top bg-dark cover"
            height="180"
            alt=""
            src={BASE_URL_IMG + product.iconURL}
          />
        </Link>
      </div>

      <div className="card-body">
        <h5 className="card-title text-center text-dark text-truncate">
          {product.title}
        </h5>

        {/* <div className="card-title text-center text-dark text-truncate">
            <Ratings
              rating={4.5}
              widgetRatedColors="rgb(253, 204, 13)"
              // changeRating={changeRating}
              widgetSpacings="2px"
            >
              {Array.from({ length: 5 }, (_, i) => {
                return (
                  <Ratings.Widget
                    key={i}
                    widgetDimension="20px"
                    svgIconViewBox="0 0 19 20"
                    svgIconPath={iconPath}
                    widgetHoverColor="rgb(253, 204, 13)"
                  />
                );
              })}
            </Ratings>
          </div> */}

        {/* <p className="card-text text-center text-muted">
            {"₹" + product.price + "/- " + "" + "$" + product.priceInDollar}
          </p> */}
        <div>
          {" "}
          {product?.description ? (
            <div class="">
              {JSON.parse(product?.description).map((item, index) => (
                <div class="col mt-2">
                  {itemPrice === index ? (
                    <div class="">
                      <div class=" ">
                        <div class="">
                          <div class="">
                            <div class="">
                              {/* <input
                                  // class="form-check-input"
                                  // type="radio"
                                  // name="flexRadioDefault"
                                  checked
                                  id={item?.price}
                                  value={item}
                                  onChange={(e) =>
                                    handleAddressIdchang(e, item)
                                  }
                                ></input> */}
                            </div>
                            <div class="">
                              <h5>
                                {"₹" +
                                  item?.price +
                                  // language+
                                  "/- " +
                                  "" +
                                  "$" +
                                  item?.priceInDoller}
                              </h5>
                              <p class="card-title">
                                {item?.days} <br></br>
                                {item?.priests}
                                <br></br>
                                {/* {item.chants} */}
                              </p>
                            </div>
                          </div>

                          {/* <p class="card-text">{parse(`${item?.viewMoreTitle}`)}</p> */}
                          <p class="card-text">
                            {/* {parse(`${newDescription.map((item)=>{   <p> {item.priceInDoller}</p>})}`)} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          ) : (
            ""
          )}{" "}
        </div>

        <div className="d-grid gap-2">
          <button
            className="btn btn-outline-primary mt-3"
            //  onClick={addToMycart}
            onClick={handleAddressIdchang}
            type="submit"
          >
            <FontAwesomeIcon icon={["fas", "cart-plus"]} /> Add to cart
          </button>
        </div>
        <div className="d-grid gap-2">
          {/* <button
              className="btn btn-outline-dark mt-3"
              onClick={() =>
                navigate(
                  "/addcatitem"  ,
                  localStorage.setItem("catItem", JSON.stringify(product))
                )
              }
              type="submit"
            >
              <FontAwesomeIcon icon="fa-solid fa-pen-to-square" /> Edit
            </button> */}
        </div>
      </div>
    </div>
    // </div>
  );
}

export default FeatureProduct;
