
import {
  SESSION_ID,
  CUSTOMER_ID,
  SetOrders,
  BASE_URL,
  ACCESS_TOKEN,
  MYPRODUCT,
  LOGO_DRIVE_ICON,
  GOOGLE_DRIVE_URL,
  USER_INFO,
  DEBUG_LOG_ENABLED,
  ID_REZORPAY,
  STARTRAZORPAYPAYMENT,
  PAYPAL_CLIENT_ID,
  startPhonePePayment,
  PAYPAL_SENDBOX_CLIENT_ID,
  GET_CLIENT_ID,
  BASE_URL_IMG,
} from "../Constant";
import { useState, useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import { Link } from "react-router-dom";
// import { useDispatch } from "react-redux";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import swal from "sweetalert";


import CheckoutStepper from "../components/CheckoutStepper";
import moment from "moment";
import axios from "axios";
import { useDispatch } from "react-redux";
import { increment } from "../actions";
function OrderReview() {
  // const [sdkReady, setSdkReady] = useState(false);
  // const dispatch = useDispatch();
  const [addressId, setAddressId] = useState();
    const dispatch = useDispatch();
  // const customerId = localStorage.getItem(CUSTOMER_ID);
  const paypalAmount = localStorage.getItem("price");
  const currencyCode = localStorage.getItem("currency");
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(true);
  // const [customerAddress, setCustomerAddress] = useState([]);

  const myData = localStorage.getItem("cartListData");
 
  const catItemList = JSON.parse(myData);

  const addressData = localStorage.getItem("addressData");
  const addressList = JSON.parse(addressData);

  // const [show, setShow] = useState(false);
  const [paypalsuccess, setPaypalsuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");

  const [orderID, setOrderID] = useState("");
  // const UpiOderId = Math.floor(100000 + Math.random() * 900000);
  // const current_date = new Date();
  const userData = localStorage.getItem(USER_INFO);
  const profiledata = JSON.parse(userData);
  const [twoPayMerchantId, setTwoPayMerchantId] = useState(null);
  const [twoPaySecretKey, setTwoPaySecretKey] = useState(null);
  const [RedirectUrl, setRedirectUrl] = useState("");

  const [Paymentloder, setPaymentloder] = useState(false);

  const cash_payment = 0;
  const online_payment = 1;

  const initializeRazorpay = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      // document.body.appendChild(script);

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };



  // const makePayment = async () => {
  //   if (DEBUG_LOG_ENABLED) {
  //     console.log("here...");
  //   }
  //   const res = await initializeRazorpay();
  //   // const order = await createOrder(params);

  //   const razorpayOrder = JSON.stringify({
  //     pubInfo: {
  //       sessionId: localStorage.getItem(SESSION_ID),
  //     },
  //     request: {
  //       busiParams: {
  //         customerId: localStorage.getItem(CUSTOMER_ID),
  //         amount: (paypalAmount * 100).toString(),
  //         currency: currencyCode,
  //       },
  //       isEncrypt: false,
  //       transactionId: "897987987989",
  //     },
  //   });

  //   let response = await axios.post(
  //     BASE_URL + STARTRAZORPAYPAYMENT,
  //     razorpayOrder,
  //     {
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //         "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
  //         "Access-Control-Allow-Headers": "Content-Type",
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
  //       },
  //     }
  //   );

  //   var mOrderId = response?.data?.respData?.respMsg?.orderId;
  //   if (DEBUG_LOG_ENABLED) {
  //     console.log(mOrderId);
  //   }
  //   if (!res) {
  //     swal("Razorpay SDK Failed to load");
  //     return;
  //   }
   


  //   var options = {
  //     key: "B5jhOHdR1DDoTIlwPvJJ4KTE",

  //     //localStorage.getItem(ID_REZORPAY), // Enter the Key ID generated from the Dashboard
  //     name: "Shree Sarv Shakti",
  //     currency: currencyCode,
  //     // amount: (paypalAmount * 100).toString(),
  //     amount: (paypalAmount * 100).toString(),

  //     order_id: mOrderId,
  //     description: "Thankyou for your test donation",
  //     image: GOOGLE_DRIVE_URL + LOGO_DRIVE_ICON,
  //     handler: function (response) {
  //       // Validate payment at server - using webhooks is a better idea.
  //       // swal(response.razorpay_payment_id);
  //       if (response.razorpay_payment_id !== "") {
  //         setOrder(response.razorpay_payment_id);
  //       }

  //       // swal(response.razorpay_order_id);
  //       // swal(response.razorpay_signature);
  //     },
  //     prefill: {
  //       name: addressList?.name,
  //       email: profiledata?.emailId,
  //       contact: addressList?.mobileNo,
  //     },
  //   };

  //   const paymentObject = new window.Razorpay(options);
  //   paymentObject.open();
  // };

  const makePayment = async () => {
    if (DEBUG_LOG_ENABLED) {
      console.log("here...");
    }
  
    const res = await initializeRazorpay();
    if (!res) {
      swal("Razorpay SDK Failed to load");
      return;
    }
  
    const razorpayOrder = JSON.stringify({
      pubInfo: {
        sessionId: localStorage.getItem(SESSION_ID),
      },
      request: {
        busiParams: {
          customerId: localStorage.getItem(CUSTOMER_ID),
          amount: (paypalAmount * 100).toString(),
          currency: currencyCode,
        },
        isEncrypt: false,
        transactionId: "897987987989",
      },
    });
  
    let response;
    try {
      response = await axios.post(
        BASE_URL + STARTRAZORPAYPAYMENT,
        razorpayOrder,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );
    } catch (error) {
      console.error("API call error:", error);
      swal("Failed to create Razorpay order");
      return;
    }
  
    const mOrderId = response?.data?.respData?.respMsg?.orderId;
    if (!mOrderId) {
      swal("Failed to create Razorpay order");
      return;
    }
  
    const options = {
      key: localStorage.getItem(ID_REZORPAY),
      name: "shreesarvshakti",
      currency: currencyCode,
      amount: (paypalAmount * 100).toString(),
      order_id: mOrderId,
      description: catItemList?.title,
      image: "",
      handler: function (response) {
        console.log("response", response);
        if (response.razorpay_payment_id) {
          // console.log("razorpay_payment_id", response.razorpay_payment_id);
          // console.log("razorpay_order_id", response.razorpay_order_id);
          // console.log("razorpay_signature", response.razorpay_signature);
          paymentObject.close();
          setOrder();
        } else {
          navigate("/OrderCancel");
        }
      },
      prefill: {
        name: addressList?.name,
        email: profiledata?.emailId,
        contact: addressList?.mobileNo,
      },
      notes: {
        address: "UP",
      },
      theme: {
        color: "#5406a1",
      },
    };
  
    console.log("Razorpay options:", options);
  
    const paymentObject = new window.Razorpay(options);
    paymentObject.on('payment.failed', function(response) {
      console.error("Payment failed:", response.error);
      swal("Payment failed");
    });
    paymentObject.open();
  };
  

  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "India",
            amount: {
              currency_code: currencyCode,
              value: paypalAmount,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;

      setPaypalsuccess(true);
    });
  };
  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };



  useEffect(() => {
    if (currencyCode === "INR") {
      // getClient();
    }

    if (paypalsuccess) {
      //  swal("Payment successful!!" + orderID);
      setOrder(orderID, online_payment ,"");
    } else {
      if (DEBUG_LOG_ENABLED) {
        console.log(1, orderID);
      }
      if (orderID !== "") {
        swal("Payment Fail!!" + ErrorMessage);
      }
      if (DEBUG_LOG_ENABLED) {
        console.log(2, paypalsuccess);
        console.log(3, ErrorMessage);
      }
    }
    const customerId = localStorage.getItem(CUSTOMER_ID);
    if (customerId === undefined || customerId === null) {
      localStorage.setItem(CUSTOMER_ID, "");
      navigate("/login");
    } else {
      setAddressId(addressList.id);
    }
    // localStorage.setItem("UpiOderId" ,UpiOderId);
  }, [paypalsuccess]);

  const setOrder = async (orderID, paymentType, dataUrl) => {
    try {
      const setOrderItem = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            orderId: orderID,
            amount: paypalAmount,
            customerId: localStorage.getItem(CUSTOMER_ID),
            paymentId: orderID,
            addressId: addressId,
            date: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
            paymentType: paymentType,
            currency: currencyCode,
            customerPhone: addressList.mobileNo,
            address: localStorage.getItem("addressData"),
          },
          isEncrypt: false,
          transactionId: "" + moment().valueOf(),
        },
      });
      // const response = await OrderReviewService.create(setOrderItem);
      let response = await axios.post(BASE_URL + SetOrders, setOrderItem, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });

          dispatch(increment(0));
        
         
            localStorage.setItem("price", "");
            localStorage.setItem("currency", "");
            // localStorage.setItem("cartListData", JSON.stringify(cartList));
            localStorage.setItem("clientId", "");
            localStorage.setItem("razorpayKeyId", "");
     




      localStorage.setItem("orderID", orderID);
      if (dataUrl === "") {
        navigate("/ordersuccess");
      }else{
        navigate("/Phonpe", { state: { dataUrl } })
      }

      

    } catch (err) {
      swal("data : " + err);
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };

  const onPhonpe = async () => {
    try {
      const PhonpeData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            amount: paypalAmount * 100,
            currency: "INR",
            customerId:localStorage.getItem(CUSTOMER_ID),
            deviceOS: "IOS",
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(
        BASE_URL + startPhonePePayment,
        PhonpeData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );
    



      setOrder(response.data.respData.respMsg.merchantTransactionId, online_payment,response.data.respData.respMsg.instrumentResponse.redirectInfo.url);
  
   
    
  
  
    } catch (err) {
   alert( "errr----------"+err)
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };


//localStorage.getItem(PAYPAL_SENDBOX_CLIENT_ID);
  return (
    <PayPalScriptProvider
      options={{
        "client-id": localStorage.getItem(PAYPAL_CLIENT_ID),
      }}
    >
      <div className="  align-items-center container  py-5 px-xl-5 mx-auto mt-5 maxWidth 900px">
        <div className="row">
          <div className="col-md-12">
            <CheckoutStepper step={2} />
          </div>
        </div>
        <div className="wrapper">
          <div className="col-lg-9">
            <div className="d-flex flex-column ">
              <ScrollToTopOnMount />
              <div className="card p-4  bg-transparent ">
                <p>
                  <span class="h4"> Address </span>
                </p>
                <hr />
                <h5>{addressList.name}</h5>
                <label class="form-check-label" for="flexRadioDefault1">
                  {addressList.addressLine1 + " " + addressList.addressLine2}
                </label>
                <p class="form-check-label">
                  {addressList.city +
                    " " +
                    addressList.state +
                    " " +
                    addressList.country +
                    " " +
                    addressList.pincode}
                </p>
                <p class="form-check-label">
                  {"Mobile no : " + addressList.mobileNo}
                </p>
              </div>

              <div class="card mb-5 bg-transparent border-3 ">
                <div class="card-body p-4">
                  <p>
                    <span class="h4"> Items in cart </span>
                  </p>
                  <form>
                    {catItemList?.map((item, index) => {
                      return (
                        <div>
                          <section>
                            <div class="container h-100 ">
                              <div class="row d-flex justify-content-center align-items-center h-100">
                                <div class="col">
                                  <div class="card mb-4 bg-transparent ">
                                    <div class="card-body p-4">
                                      <div class="row align-items-center">
                                        <div class="col-md-2">
                                          <Link
                                            to={"/productdetail/" + item.itemId}
                                            onClick={() =>
                                              localStorage.setItem(
                                                MYPRODUCT,
                                                JSON.stringify(item)
                                              )
                                            }
                                          >
                                            <img
                                              width="100  fluid  rounded-3"
                                              height="120"
                                              src={BASE_URL_IMG + item.iconURL}
                                              alt="iconURL"
                                            />
                                          </Link>
                                        </div>
                                        <div className="col-md-10 card-title text-left text-dark text-truncate">
                                          {/* <div>
                                            <h6>{item.title}</h6>
                                            <h5>
                                              <span>₹</span>
                                              {item.price}
                                            </h5>
                                            <h5>
                                              <span>$</span>
                                              {item.priceInDollar}
                                            </h5>
                                          </div> */}
                                          <div>
                                            <h6 className="h5">{item.title}</h6>
                                            <h5>
                                              {currencyCode === "USD"
                                                ? "$" + item.panditCount
                                                : " ₹" + item.price}
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      );
                    })}

                    <div class="clearfix bg-primary">
                      <div class="float-start">
                        <p class="mb-0 me-5 d-flex align-items-center">
                          <span class="h4"> Subtotal: </span>
                        </p>
                      </div>
                      <div class="float-end">
                        <p class="mb-0 me-5 d-flex align-items-center">
                          <span class="h4">
                            {currencyCode === "USD" ? " $" : " ₹"}
                          </span>
                          <span class="h4">{paypalAmount}</span>
                        </p>
                      </div>
                    </div>
                  </form>
                  <div class="d-flex justify-content-center pt-3">
                    {/* <PayPalButtons
                      style={{ layout: "vertical" }}
                      createOrder={createOrder}
                      onApprove={onApprove}
                    /> */}
                  </div>
                  <div class=" content-center pt-3 w-100">
                    <span class="h4">
                      {currencyCode === "USD" ? (
                        <>
                          <PayPalButtons
                            style={{ layout: "vertical" }}
                            createOrder={createOrder}
                            onApprove={onApprove}
                          />

                          <hr class="hr hr-blurry p-3" data-content="OR" />
                        </>
                      ) : (
                        <>
                          <button
                            style={{ layout: "vertical" }}
                            class="btn btn-primary btn-lg w-100"
                            onClick={makePayment}
                          >
                            Razorpay Now
                        
                          </button>

                          <button
                            class="btn btn-primary btn-lg w-100 mt-3"
                            onClick={onPhonpe}
                          >
                            Continue to pay
                          </button>
                        </>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PayPalScriptProvider>
  );
}

export default OrderReview;
