module.exports = Object.freeze({
  TOP_BANNER: "top_banner_01",
  TOP_PRODUCT: "top_product_01",
  CARD_VIEW: "card_view_01",
  BRAND_HEADING_VIEW: "brand_heading_view",
  ABOUT_US_VIEW: "about_us_view",
  CUSTOMER_ID: "customerId",
  SESSION_ID: "sessionId",
  ACCESS_TOKEN: "shree_access_token",
  USER_ROLE: "user_role",
  ROLE: "ROLE_ADMIN",
  USER_INFO: "user_info",
  USER_LOGIN: "user",
  ADD_CART: "add_cart",
  BASE_URL: "https://shreesarvshakti.com/shreeapi-v1/",
   BASE_URL_IMG:"https://shreesarvshakti.com/img/",

   CART_COUNT: "cart_count",


  GET_CART_COUNT: "getCartCount",
  ADD_TO_CART: "addToCart",
  GET_CLIENT_ID: "getPaypalClientId",
  GET_CART_ITEM: "getCartItem",
  GET_OREDER_HISTORY: "getOrderInfosByCustomerId",
  GetOrderInfosByDetails: "getOrderInfosByDetails",
  DELETE_CATEGORY: "deleteCategory",
  AddCategory: "addCategory",
  ChangeOrderStatus: "changeOrderStatus",
  generateSmsOtp: "generateSmsOtp",

  validateSmsOtp: "validateSmsOtp",
  AddCategoryItem: "addCategoryItem",
  DeleteItem: "deleteItem",
  GET_LOGOUT: "login",
  SetOrders: "setOrders",
  RemoveFromCart: "removeFromCart",
  AddPassword: "addPassword",
  PRODUCT_LIST: "getCategoryItemLists",


  SEARCH_API: "findSearchByItemTitle",
  getCustomerAddressByUserId: "getCustomerAddressByUserId",
  addCustomerAddress: "addCustomerAddress",
  removeCustomerAddress: "removeCustomerAddress",
  MYPRODUCT: "myProduct",
  PRICE: "price",
  CURRENCY: "currency",
  MYDATA: "myData",
  CATDATA: "catData",
  getCategoryItemByItemId: "getCategoryItemByItemId",
  DEEPLINK_URL: "https://shreesarvshakti.com/#/productdetail/",
  getOrderInfoByDate: "getOrderInfoByDate",
  CARTLISTDATA: "cartListData",
  ADDRESSLIST: "addressList",
  ADDRESSDATA: "addressData",
  PAYPAL_CLIENT_ID: "clientId",
  PAYPAL_SENDBOX_CLIENT_ID: "sendbox_clientId",
  ID_REZORPAY: "rezorpay_id",
  CATITEM: "catItem",
  CATEGORYDATA: "categorydata",
  GOOGLE_DRIVE_URL: "https://drive.google.com/uc?id=",
  LOGO_DRIVE_ICON: "1R0acFcUNqcLmpOsLtmvywratnDshO9x2",
  DEBUG_LOG_ENABLED: false,
  STARTRAZORPAYPAYMENT: "startRazorpayPayment",
  GetCategoryLists: "getCategoryLists",
  you_tube_view: "you_tube_view",
  GOOGLE_ANALYTICS_TRACKING_ID: "G-8BMXKXFKVN",
  startPhonePePayment:"startPhonePePayment",
  BACKGROUNDIMAGE:
    'url("https://mdbootstrap.com/img/Photos/Others/images/76.jpg")',
});
