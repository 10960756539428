import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import {
  SESSION_ID,
  CUSTOMER_ID,
  GET_CLIENT_ID,
  ACCESS_TOKEN,
  BASE_URL,
  ADDRESSLIST,
  ADDRESSDATA,
  getCustomerAddressByUserId,
  addCustomerAddress,
  removeCustomerAddress,
  PAYPAL_CLIENT_ID,
  PAYPAL_SENDBOX_CLIENT_ID,
  DEBUG_LOG_ENABLED,
  ID_REZORPAY,
} from "../Constant";
import { useState, useEffect } from "react";
import swal from "sweetalert";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import RemoveAddressService from "../oder_review/RemoveAddressService";
import axios from "axios";
import CheckoutStepper from "../components/CheckoutStepper";
function AddAddress() {
  const dispatch = useDispatch();
  const [customerAddress, setCustomerAddress] = useState([]);
  const [addressId, setAddressId] = useState("");
  const [show, setShow] = useState(true);
  const [clientID, setClientID] = useState("");
  const [addressShow, setAddressShow] = useState(false);

  const getAddressLists = async () => {
    try {
      const customerData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            customerId: customerId,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(
        BASE_URL + getCustomerAddressByUserId,
        customerData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );

      setCustomerAddress(response.data.respData.respMsg);
      localStorage.setItem(
        ADDRESSLIST,
        JSON.stringify(response.data.respData.respMsg)
      );
        if (response?.data?.respData?.respMsg.length === 0) {
          setAddressShow(true);
        } else {
          setAddressShow(false);
        }
    } catch (err) {
      if (err.response.status == 401) {
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };
  const handleAddressIdchang = (e, item) => {
    const value = e.target.value;
    localStorage.setItem(ADDRESSDATA, JSON.stringify(item));
    setAddressId(value);
  };

  const customerId = localStorage.getItem(CUSTOMER_ID);

  const [customer, setCustomer] = useState({
    customerId: customerId,
    name: "",
    addressLine1: "",
    addressLine2: "",
    landmark: "",
    city: "",
    state: "",
    purpose: "",
    pincode: "",
    country: "",
    mobileNo: "",
    defaultAddress: false,
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setCustomer({ ...customer, [e.target.name]: value });
  };

  const navigate = useNavigate();
  //const responseJson = navigate.getParam("myJSON");

  const [catItemList, setCartItemList] = useState([]);

  const reviewOrder = (e) => {
    e.preventDefault();
    if (addressId === "" && clientID === "") {
      swal("Please select your address");
    } else {
      navigate("/orderreview");
    }
  };

  const saveAddress = async (e) => {
    e.preventDefault();
    try {
      if (
        customer.name === "" ||
        customer.mobileNo === "" ||
        customer.addressLine1 === "" ||
        customer.addressLine2 === "" ||
        customer.city === "" ||
        customer.state === "" ||
        customer.country === "" ||
        customer.pincode === ""
      ) {
        swal(" Please enter all fields ");
      } else {
        const customerData = JSON.stringify({
          pubInfo: {
            sessionId: localStorage.getItem(SESSION_ID),
          },
          request: {
            busiParams: {
              customerId: customerId,
              name: customer.name,
              addressLine1: customer.addressLine1,
              addressLine2: customer.addressLine2,
              city: customer.city,
              state: customer.state,
              purpose: customer.purpose,
              country: customer.country,
              pincode: customer.pincode,
              landmark: customer.landmark,
              mobileNo: customer.mobileNo,
              defaultAddress: customer.defaultAddress,
            },
            isEncrypt: false,
            transactionId: "897987987989",
          },
        });

        let response = await axios.post(
          BASE_URL + addCustomerAddress,
          customerData,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
              "Access-Control-Allow-Headers": "Content-Type",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
            },
          }
        );

        swal(" " + response.data.respData.message);
        if (DEBUG_LOG_ENABLED) {
          console.log(response.data);
        }
        getAddressLists();
      }
    } catch (err) {
      if (err.response.status == 401) {
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  const removeAddress = async (item) => {
    try {
      const removeAddressItem = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            customerId: localStorage.getItem(CUSTOMER_ID),
            addressId: item.id,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(
        BASE_URL + removeCustomerAddress,
        removeAddressItem,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );

      swal(" " + response.data.respData.message);
      getAddressLists();
    } catch (err) {
      if (err.response.status == 401) {
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  const removeItem = (item) => {
    removeAddress(item);
  };

  const getClient = async () => {
    try {
      const getClientId = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            customerId: localStorage.getItem(CUSTOMER_ID),
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      // const response = await OrderReviewService.create(setOrderItem);
      let response = await axios.post(BASE_URL + GET_CLIENT_ID, getClientId, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });
      setClientID(response.data.respData.respMsg.paypalClientId);
      localStorage.setItem(
        PAYPAL_CLIENT_ID,
        response?.data?.respData?.respMsg?.paypalClientId
      );
        localStorage.setItem(
          PAYPAL_SENDBOX_CLIENT_ID,
          response?.data?.respData?.respMsg?.sandboxPaypalClientId
        );

      localStorage.setItem(
        ID_REZORPAY,
        response?.data?.respData?.respMsg?.razorpayKeyId
      );


      // response?.data?.respData?.respMsg?.paypalClientId;
      // response?.data?.respData?.respMsg?.paypalSecretId;
      // response?.data?.respData?.respMsg?.razorpayKeyId;
      // response?.data?.respData?.respMsg?.razorpayKeySecret;
      // response?.data?.respData?.respMsg?.sandboxPaypalClientId;
      // response?.data?.respData?.respMsg?.sandboxPaypalSecretId;
      // response?.data?.respData?.respMsg?.sandboxRazorpayKeyId;
      // response?.data?.respData?.respMsg?.sandboxRazorpayKeySecret;
      // response?.data?.respData?.respMsg?.twoPayMerchantId;
      //       response?.data?.respData?.respMsg?.twoPaySecretKey;

    
    } catch (err) {
      swal("data : " + err);
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    const customerId = localStorage.getItem(CUSTOMER_ID);
    if (customerId === undefined || customerId === null) {
      localStorage.setItem(CUSTOMER_ID, "");
      navigate("/login");
    } else {
      getClient();
      getAddressLists();
    }
  }, []);

  return (
    <div className="  align-items-center container  py-5 px-xl-5 mx-auto mt-5 maxWidth 900px">
      <div className="wrapper">
        <div className="col-lg-9">
          <div className="d-flex flex-column ">
            <ScrollToTopOnMount />

            <div className="row">
              <div className="col-md-12">
                <CheckoutStepper />
              </div>
            </div>

            <div class="card mb-5 bg-transparent ">
              <div class="card-body p-4 ">
                <form>
                  <div class="form-group">
                    <label className="h4">Select Address</label>
                    {customerAddress.map((item, index) => (
                      <div class="swal swal-info clearfix" key={index}>
                        <div class="form-check" id="form-check1">
                          <input
                            class="form-check-input"
                            value={item.id}
                            onChange={(e) => handleAddressIdchang(e, item)}
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                          />
                          <h5>{item.name}</h5>
                          <label
                            class="form-check-label"
                            for="flexRadioDefault1"
                          >
                            {item.addressLine1 + " " + item.addressLine2}
                          </label>
                          <p class="form-check-label">
                            {item.city +
                              " " +
                              item.state +
                              " " +
                              item.country +
                              " " +
                              item.pincode}
                          </p>
                          <p class="form-check-label">
                            {"Mobile no : " + item.mobileNo}
                          </p>
                        </div>
                        <div>
                          <button
                            class="btn btn-transparent btn-lg float-end"
                            for="form-check1"
                            onClick={() => removeItem(item)}
                            type="submit"
                          >
                            <FontAwesomeIcon icon="fa-trash" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </form>
              </div>
            </div>

            {/* {show ? ( */}
              <form class="row g-3  ">
                <div
                  className="accordion accordion-flush "
                  id="accordionFlushExample"
                 // onClick={ ()=> setAddressShow(!addressShow)}
                >
                  <div className="accordion-item ">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                      
                        className={`accordion-button ${addressShow ? '' : 'collapsed'} bg-info`}
                        type="button"
                        onClick={() => setAddressShow(!addressShow)}
                        aria-expanded={addressShow ? 'true' : 'false'}
                        aria-controls="flush-collapseOne"
                       data-bs-toggle="collapse"
                      >
                        <span class="h1"> Add new address </span>
                      </button>
                    </h2>

                    <div
                      id="flush-collapseOne"
                   
                      className={`accordion-collapse collapse ${addressShow ? 'show' : ''}`}
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="card p-4  bg-info">
                        <div class="row g-3">
                          <div class="col-md-4 ">
                            <label for="validationCustom01" class="form-label">
                              Full name
                            </label>
                            <input
                              class="form-control"
                              id="validationServer01"
                              type="text"
                              name="name"
                              required
                              value={customer.name}
                              onChange={(e) => handleChange(e)}
                              placeholder="Full Name"
                              maxlength="100"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Mobile Number</label>
                            <input
                              type="text"
                              name="mobileNo"
                              required="true"
                              value={customer.mobileNo}
                              onChange={(e) => handleChange(e)}
                              className="form-control mt-1"
                              placeholder="Mobile Number"
                              maxlength="12"
                            />
                          </div>
                        </div>
                        <div class="row g-3">
                          <div class="col-md-4">
                            <label>Flat No & Building name </label>
                            <input
                              type="text"
                              name="addressLine1"
                              required="true"
                              value={customer.addressLine1}
                              onChange={(e) => handleChange(e)}
                              className="form-control mt-1"
                              placeholder="Flat No & Building name"
                              maxlength="100"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Locality</label>
                            <input
                              type="text"
                              name="addressLine2"
                              aria-required
                              value={customer.addressLine2}
                              onChange={(e) => handleChange(e)}
                              className="form-control mt-1"
                              placeholder="Locality"
                              maxlength="100"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>City</label>
                            <input
                              type="text"
                              name="city"
                              required="true"
                              value={customer.city}
                              onChange={(e) => handleChange(e)}
                              className="form-control mt-1"
                              placeholder="City"
                              maxlength="100"
                            />
                          </div>
                        </div>

                        <div class="row g-3">
                          <div class="col-md-4">
                            <label>State</label>
                            <input
                              type="text"
                              name="state"
                              required="true"
                              value={customer.state}
                              onChange={(e) => handleChange(e)}
                              className="form-control mt-1"
                              placeholder="state"
                              maxlength="50"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Country</label>
                            <input
                              type="text"
                              name="country"
                              required="true"
                              value={customer.country}
                              onChange={(e) => handleChange(e)}
                              className="form-control mt-1"
                              placeholder="country"
                              maxlength="20"
                            />
                          </div>
                          <div class="col-md-4">
                            <label>Zip</label>
                            <input
                              type="numbar"
                              name="pincode"
                              required="true"
                              value={customer.pincode}
                              onChange={(e) => handleChange(e)}
                              className="form-control mt-1"
                              placeholder="pincode"
                              maxlength="10"
                            />
                          </div>
                        </div>

                        <div class="form-group">
                          <label>Sankalp (puja ka Uddesh)</label>
                          <textarea
                            class="form-control "
                            name="purpose"
                            required="true"
                            value={customer.purpose}
                            onChange={(e) => handleChange(e)}
                            placeholder="sankalp"
                            rows="2"
                            maxlength="100"
                          ></textarea>
                          <div class="col-12 pt-1">
                            <div class="form-check">
                              <input
                                class="form-check-input is-valid"
                                type="checkbox"
                                value=""
                                id="invalidCheck3"
                                aria-describedby="validCheck3Feedback"
                                required
                              />
                              <label
                                class="form-check-label"
                                for="invalidCheck3"
                              >
                                Mark as default address
                              </label>
                            </div>
                          </div>

                          <div class="d-flex justify-content-end ">
                            <button
                              className="btn btn-outline-dark mt-3"
                              for="form-check1"
                              onClick={saveAddress}
                              type="submit"
                            >
                              <FontAwesomeIcon icon="fa-floppy-disk" />
                              {" " + "save"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            {/* ) : null} */}
          </div>
          <div class="d-flex justify-content-end pt-5 ">
            <button
              type="button"
              onClick={reviewOrder}
              class="btn btn-primary btn-lg"
            >
              Deliver to this address
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAddress;
