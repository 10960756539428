import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import FeatureProduct from "../landing/FeatureProduct";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import RemoveCartItemService from "./RemoveCartItemService";
import { increment } from "../actions";
import swal from "sweetalert";

import moment from "moment";
import {
  SESSION_ID,
  CUSTOMER_ID,
  GET_CART_ITEM,
  RemoveFromCart,
  ACCESS_TOKEN,
  BASE_URL,
  ADD_TO_CART,
  CURRENCY,
  PRICE,
  CARTLISTDATA,
  MYPRODUCT,
  DEBUG_LOG_ENABLED,
  BASE_URL_IMG,
} from "../Constant";
import axios from "axios";

function AddToCart() {
  const dispatch = useDispatch();
  const [date, setDate] = useState("");

  var totalCartPrice = 0;
  var totalCartPriceDollar = 0;

  const [price, setPrice] = useState(0);
  const [currency, setCurrency] = useState("USD");

  const handleChange = (e) => {
    const value = e.target.value;
    var dataArray = value.split(" ");
    if (dataArray[0] === "₹") {
      setCurrency("INR");
    } else if (dataArray[0] === "$") {
      setCurrency("USD");
    }

    setPrice(parseFloat(dataArray[1]));
  };

  const navigate = useNavigate();
  //const responseJson = navigate.getParam("myJSON");

  const [catItemList, setCartItemList] = useState([]);

  const getCartLists = async () => {
    try {
      const cartListData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            customerId: localStorage.getItem(CUSTOMER_ID),
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      //const response = await GetCartItemService.create(cartListData);
      let response = await axios.post(BASE_URL + GET_CART_ITEM, cartListData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });
      setCartItemList(response?.data?.respData?.respMsg?.cartList);
      if (DEBUG_LOG_ENABLED) {
        console.log("catItemList : " + catItemList);
      }
      //swal("data : "+catItemList.length);
    } catch (err) {
      if (err.response.status == 401 || err.response.status == 404) {
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };
  useEffect(() => {
    const customerId = localStorage.getItem(CUSTOMER_ID);
    if (customerId === undefined || customerId === null || customerId === "") {
      localStorage.setItem(CUSTOMER_ID, "");
      navigate("/login");
    } else {
      getCartLists();
    }
  }, []);

  const removeItemFromCart = async (item) => {
    try {
      const removeCartItem = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            customerId: localStorage.getItem(CUSTOMER_ID),
            itemId: item.itemId,
            price: item.price,
            panditCount: item.panditCount,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      // const response = await RemoveCartItemService.create(removeCartItem);
      let response = await axios.post(
        BASE_URL + RemoveFromCart,
        removeCartItem,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );

      dispatch(increment(response?.data?.respData?.respMsg?.cartCount));
      swal(response?.data?.respData?.message);
      getCartLists();
    } catch (err) {
      if (err.response.status == 401) {
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  const removeItem = (item) => {
    removeItemFromCart(item);
  };

  const handleDateChange = (e, item) => {
    e.preventDefault();
    const value = e.target.value;
    const date = moment(Date(value)).format("YYYY-MM-DD HH:mm:ss.SSS");
    setDate(date);
    addToCart(value, item);
    e.preventDefault();
  };
  const addToCart = async (value, item) => {
    try {
      const itemData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            id: 0,
            customerId: localStorage.getItem(CUSTOMER_ID),
            itemId: item.itemId,
            price: item.price,

            date: value,
            panditCount:item.panditCount
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      if (DEBUG_LOG_ENABLED) {
        console.log("CartService : " + itemData);
      }

      // const response = await CartService.addToCart(itemData);

      let response = await axios.post(BASE_URL + ADD_TO_CART, itemData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });
      dispatch(increment(response.data?.respData?.respMsg?.cartCount));
      swal(response?.data?.respData?.message);
      getCartLists();
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.setItem(CUSTOMER_ID, "");
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  const shippingDetail = (e) => {
    localStorage.setItem(CARTLISTDATA, JSON.stringify(catItemList));
    localStorage.setItem(PRICE, price);
    localStorage.setItem(CURRENCY, currency);
    if (price === 0) {
      swal("Please select the given amount to pay");
    } else if ((catItemList[0].date = "")) {
      swal("Please select the given date of pujan to processed");
    } else {
      navigate("/addaddress");
    }
  };

  return (
    <div className="container " style={{paddingTop:100 ,paddingBottom:100}}>
      <div className="col-lg-9">
        {catItemList?.length === 0 ? (
          <div class="row d-flex justify-content-center align-items-center h-100 h4">
            Shopping cart has no item, please add item.
          </div>
        ) : (
          <div className="d-flex flex-column h-100">
            <ScrollToTopOnMount />
            <p>
              <span class="h4"> Cart Items </span>
            </p>
            {catItemList?.map((item, index) => {
              //panditCount  const dollarResult = parseFloat(item.priceInDollar);
              const dollarResult = parseFloat(item.panditCount);
              const result = parseFloat(item.price);
              totalCartPrice += result;
              totalCartPriceDollar += dollarResult;

              return (
                <div>
                  <section>
                    <div class="container h-100">
                      <div class="row d-flex justify-content-center align-items-center h-100">
                        <div class="col">
                          <div class="card mb-4 bg-transparent ">
                            <div class="card-body p-4 ">
                              <div class="row align-items-center">
                                <div class="col-md-2 ">
                                  <Link
                                    to={"/productdetail/" + item?.itemId}
                                    onClick={() =>
                                      localStorage.setItem(
                                        MYPRODUCT,
                                        JSON.stringify(item)
                                      )
                                    }
                                  >
                                    <img
                                      width="100  fluid  rounded-3"
                                      height="120"
                                      src={BASE_URL_IMG + item?.iconURL}
                                      alt="iconURL"
                                    />
                                  </Link>
                                </div>
                                <div className="col-md-10 card-title text-left text-dark text-truncate">
                                  <div>
                                    <h6>{item?.title}</h6>
                                    <h5>
                                      <span>₹</span>
                                      {item?.price}
                                    </h5>
                                    <h5>
                                      <span>$</span>
                                      {item?.panditCount}
                                    </h5>
                                    <label for="date">
                                      Pojan date{" "}
                                    </label>
                                    <span> </span>
                                    <input
                                      type="date"
                                      id="date"
                                      name="birthdaytime"
                                      value={item?.date}
                                      onChange={(e) =>
                                        handleDateChange(e, item)
                                      }
                                    ></input>
                                  </div>
                                  <div class="d-flex justify-content-end">
                                    <button
                                      height="50"
                                      onClick={() => removeItem(item)}
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              );
            })}

            <div>
              <div class="card-body p-4">
                <div class="float-end">
                  <p>
                    <input
                      class="form-check-input"
                      value={"₹" + " " + totalCartPrice}
                      onChange={(e) => handleChange(e)}
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <span class="h5"> </span>
                    <span class="h5">Order total:</span>
                    <span class="h5">{"₹" + " " + totalCartPrice}</span>
                  </p>
                  <p>
                    <input
                      class="form-check-input"
                      value={"$" + " " + totalCartPriceDollar}
                      onChange={(e) => handleChange(e)}
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <span class="h5"> </span>
                    <span class="h5">Order total:</span>
                    <span class="h5">{"$" + " " + totalCartPriceDollar}</span>
                  </p>
                  {/* <p>
                  {" "}
                  <span class="lead fw-normal">{price}</span>
                </p> */}
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end">
              {/* <Link
                to="/"
                replace
                className="btn btn-outline-dark mt-3"
                class="btn btn-outline-dark btn-lg me-2 text-truncate"
              >
                Continue shopping
              </Link> */}
              <button
                onClick={(e) => shippingDetail(e)}
                class="btn btn-primary btn-lg text-truncate px-5"
              >
                Proceed to buy 
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AddToCart;
