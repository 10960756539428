
import images from "../images/bg.png";
function Content(props) {
  const myStyle = {
    backgroundImage:
      `url(${images})`,
    height: "100%",
    // marginTop: "-70px",
    // fontSize: "50px",
    backgroundSize: "cover",
   backgroundRepeat: "no-repeat",
  };
  return (
    <main className="flex-shrink-0 bg-light">
      <div style={myStyle}>{props.children}</div>
    </main>
  );
}

export default Content;
