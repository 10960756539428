import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import FeatureProduct from "../landing/FeatureProduct";

import { SESSION_ID ,BASE_URL,PRODUCT_LIST,ACCESS_TOKEN, SEARCH_API, ABOUT_US_VIEW, DEBUG_LOG_ENABLED,} from "../Constant";
import axios from "axios";
import { useState, useEffect } from "react";
import GetCategoryItemService from "../services/GetCategoryItemService";
import { useNavigate } from "react-router-dom";
import SearchApi from "./SearchApi";




function ProductList() {
  const [viewType, setViewType] = useState({ grid: true });

  

  function changeViewType() {
    setViewType({
      grid: !viewType.grid,
    });
  }
  const navigate = useNavigate();

  //const responseJson = navigate.getParam("myJSON");

  const [data, setData] = useState({});
  const [catItemList, setCatItemList] = useState([]);

 
  useEffect(() => {
    const myData = localStorage.getItem("myData");
    const itemList = JSON.parse(myData);
    // if (itemList?.items?.lenght > 0) {
    //   setCatItemList(itemList.items);
    // } else {
    //   getCategoryLists();
    // }
    const value = "puja";
    Search(value);
  }, []);

  const handleChange = (e) => {const value = e.target.value;
  Search(value)};



  const Search = async (value) => {
    try {
      const searchApi= JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
           title:value 
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      // const response = await SearchApi.create(searchApi);

      let response = await axios.post(BASE_URL +  SEARCH_API, searchApi, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });



      const filteredData = response.data.respData.respMsg.catItemList.filter(
        (item) => item.viewType !== ABOUT_US_VIEW
      );

      setCatItemList(filteredData);

     
      
    } catch (err) {
      alert("data : " + err);
      if(DEBUG_LOG_ENABLED){
      console.log(err);
      }
    }
  };






  return (
    <div className="container mt-5 py-4 ">
      {/* <ScrollToTopOnMount /> */}

      {/* <div className="container">
        <div className="col-lg-9"> */}
          {/* <div className="d-flex flex-column h-100"> */}
            <div className="row mb-3">
              <div className="col-lg-11 col-xl-5 offset-xl-4 d-flex flex-row">
                <form className="d-flex">
                  <div className="input-group">
                    <input
                      className="form-control"
                      // value={searchApi}
                      onChange={(e) => handleChange(e)}
                      type="search"
                      placeholder="Search..."
                      aria-label="Search"
                      size="32"
                    />
                    <button type="button" className="btn btn-primary ">
                      <FontAwesomeIcon icon={["fas", "search"]} />
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div
              className={
                "row row-cols-2 row-cols-md-6 row-cols-lg-6 g-3  d-flex justify-content-start px-4  " +
                (viewType.grid ? "row-cols-xl-5" : "row-cols-xl-2")
              }
            >
              {catItemList.map((item, index) => {
                if (viewType.grid) {
                  return <FeatureProduct key={index} product={item} />;
                }
                return <FeatureProduct key={index} product={item} />;
              })}
            </div>
          {/* </div>
        </div> */}
      {/* </div> */}
    </div>
  );
}

export default ProductList;
